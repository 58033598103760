import React, { Component, Suspense, createContext, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Provider } from 'react-redux';
import Store from './Reduxs/Store';
import { checkAuth } from './Services/AxiosService';
import { ConfigServices } from './Services/Config';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export const UserContext = createContext()

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Loginc = React.lazy(() => import('./views/loginc/Loginc'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App =()=> {
  var [flag,setFlag] = useState(false)
  useEffect(() => { 
    checkAuth(ConfigServices.BASE_PATH_AUTHENTICATION+'isLogin').then(res=>{
      setFlag(res.flag)
    }).catch(res=>setFlag(false))
    }, []); 
    
    return (
      <UserContext.Provider value={setFlag}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Provider store={Store}>
            <Routes>
            <Route exact
							path="*"
							element={flag ? <DefaultLayout /> : <Loginc />}
						></Route>
           {/* <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
              {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
            </Routes>
          </Provider>
        </Suspense>
      </HashRouter>
      </UserContext.Provider>
    )
}

export default App
